<template>
  <div ref="dealBill" class="dealBill">
    <div ref="tabs" class="tabs">
      <el-tabs v-model="form.channelNo" type="card" @tab-click="renderData">
        <el-tab-pane
          v-for="(item, index) in channelList"
          :key="index"
          :label="item.channelName"
          :name="item.channelNo"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <dataExplain ref="explain" :list="dataExplainList"></dataExplain>
    <div ref="area" class="form-area">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="85px"
      >
        <el-form-item label="对账周期：">
          <el-date-picker
            v-model="time"
            type="daterange"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            range-separator="至"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            placeholder="请选择对账周期"
            :clearable="false"
          />
        </el-form-item>
        <el-form-item label="对账结果：">
          <el-select v-model="form.type" clearable placeholder="请选择对账结果">
            <el-option
              v-for="role in resultList"
              :key="role.value"
              :label="role.label"
              :value="role.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="queryFun"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="table">
      <Table
        :table-data="tableData"
        :title-name="titleName"
        :table-height="TableHeight"
      />
    </div>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getCurrentUserChannelInfoAPI,
  queryTransactionStatementPage,
} from "@/api/lib/api.js";
import { lastTime } from "@/tools/getTime.js";
import moment from "moment";
import dataExplain from "@/components/dataExplain/index";
export default {
  components: { dataExplain },
  data() {
    return {
      channelList: [],
      pickTime: null,
      pickerOptions: {
        disabledDate: (time) => {
          if (this.pickTime) {
            let number = 365 * 3600 * 24 * 1000;
            if (this.pickTime.getTime() + number > Date.now()) {
              return (
                time.getTime() > Date.now() - 1000 * 24 * 3600 ||
                time.getTime() < this.pickTime.getTime() - number
              );
            } else {
              return (
                time.getTime() > this.pickTime.getTime() + number ||
                time.getTime() < this.pickTime.getTime() - number
              );
            }
          } else {
            return time.getTime() > Date.now() - 1000 * 24 * 3600;
          }
        },
        onPick: ({ maxDate, minDate }) => {
          if (!maxDate && minDate) this.pickTime = minDate;
          else this.pickTime = null;
        },
      },
      TableHeight: 0,
      tableData: [],
      titleName: [
        {
          title: "平台账单",
          children: [
            {
              title: "交易时间",
              props: "platformTransactionTime",
              width: 200,
            },
            {
              title: "订单号",
              props: "platformOrderId",
              width: 200,
            },
            {
              title: "票号",
              props: "platformRefundOrderId",
              width: 200,
            },
            {
              title: "收支类型",
              SpecialJudgment: (res) =>
                res.platformOrderId ? (res.status == 1 ? "收入" : "支出") : "",
            },
            {
              title: "金额",
              render: (h, params, index) => {
                return h(
                  "span",
                  {
                    style: {
                      color: params.status == 1 ? "green" : "red",
                    },
                  },
                  params.platformTransactionAmount !== null
                    ? params.status == 1
                      ? "+" + params.platformTransactionAmount
                      : "-" + params.platformTransactionAmount
                    : ""
                );
              },
            },
          ],
        },
        {
          title: "微信账单",
          children: [
            {
              title: "交易时间",
              props: "wxTransactionTime",
              width: 200,
            },
            {
              title: "微信订单号",
              props: "wxOrderId",
              width: 200,
            },
            {
              title: "收支类型",
              SpecialJudgment: (res) =>
                res.wxOrderId ? (res.status == 1 ? "收入" : "支出") : "",
            },
            {
              title: "金额",
              render: (h, params, index) => {
                return h(
                  "span",
                  {
                    style: {
                      color: params.status == 1 ? "green" : "red",
                    },
                  },
                  params.wxTransactionAmount !== null
                    ? params.status == 1
                      ? "+" + params.wxTransactionAmount
                      : "-" + params.wxTransactionAmount
                    : ""
                );
              },
            },
          ],
        },
        {
          title: "系统备注",
          props: "remark",
        },
      ],
      time: null,
      form: {
        startDateTime: "",
        endDateTime: "",
        type: 0,
        currentPage: 1,
        pageSize: 10,
        channelNo: null,
      },
      total: 0,
      resultList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 3,
          label: "对平",
        },
        {
          value: 1,
          label: "单边",
        },
        {
          value: 2,
          label: "错账",
        },
      ],
      dataExplainList: [
        "平台将在次日10点生成前一天的对账单，建议在11点后查看",
        "微信订单数据来源：微信支付商户平台的交易账单",
        "收入数据的交易类型包含：订单收款、分账回退；支出数据的交易类型包含订单退款、分账、售后退款",
      ],
    };
  },
  mounted() {
    const query = this.$route.query;
    if (query.date) {
      this.time = [query.date, query.date];
    } else {
      const date = moment(new Date()).subtract(1, "day");
      let day = new Date(date);
      let text =
        day.getFullYear() + "-" + (day.getMonth() + 1) + "-" + day.getDate();
      this.time = [lastTime(new Date()), text];
    }
    this.getQueryChannelList();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
  methods: {
    //获取渠道数据
    getQueryChannelList() {
      getCurrentUserChannelInfoAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.channelList = res.data;
          if (this.$route.query.channelNo)
            this.form.channelNo = this.$route.query.channelNo;
          else this.form.channelNo = res.data[0]?.channelNo;
          this.renderData();
        }
      });
    },
    queryFun() {
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.renderData();
    },
    // 表格数据渲染
    renderData() {
      this.form.startDateTime = this.time[0] + " 00:00:00";
      this.form.endDateTime = this.time[1] + " 23:59:59";
      queryTransactionStatementPage(this.form).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page;
      this.renderData();
    },
    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size;
      this.form.currentPage = 1;
      this.renderData();
    },
    // 计算表格高度
    computeHeight() {
      const boxHeight = this.$refs["dealBill"].clientHeight;
      const tabHeight = this.$refs["tabs"].clientHeight;
      const explain = this.$refs["explain"].clientHeight;
      const area = this.$refs["area"].clientHeight;
      const pagination = this.$refs["pagination"].clientHeight;
      this.TableHeight =
        boxHeight - tabHeight - explain - area - pagination - 100;
    },
  },
};
</script>
<style lang="scss" scoped>
.dealBill {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  /deep/.el-range-separator {
    line-height: 26px !important;
  }
  /deep/.el-date-editor.el-input__inner {
    width: 13vw !important;
  }
  .table {
    margin-top: 10px;
  }
}
</style>
